import { CategoryDescription } from '../common/enums/categoryDescription'

import {
  IoFastFoodOutline,
  IoHomeOutline,
  IoGameControllerOutline,
  IoMedicalOutline,
  IoCarOutline,
  IoBriefcaseOutline,
  IoShirtOutline,
  IoWalletOutline,
  IoFlashOutline,
  IoReceiptOutline,
  IoAirplaneOutline,
  IoSchoolOutline,
  IoCashOutline,
  IoAttachOutline,
} from 'react-icons/io5'

export const getCategoryIcon = (category: CategoryDescription) => {
  const icons = {
    [CategoryDescription.FOOD]: (props?: object) => (
      <IoFastFoodOutline color={'sandybrown'} {...props} />
    ),
    [CategoryDescription.HOUSING]: (props?: object) => (
      <IoHomeOutline color={'#03045E'} {...props} />
    ),
    [CategoryDescription.LEISURE]: (props?: object) => (
      <IoGameControllerOutline color={'#FF5400'} {...props} />
    ),
    [CategoryDescription.HOSPITAL]: (props?: object) => (
      <IoMedicalOutline color={'#D62828'} {...props} />
    ),
    [CategoryDescription.TRANSPORT]: (props?: object) => (
      <IoCarOutline color={'#33658A'} {...props} />
    ),
    [CategoryDescription.INSURANCE]: (props?: object) => (
      <IoBriefcaseOutline color={'#EE6C4D'} {...props} />
    ),
    [CategoryDescription.CLOTHES]: (props?: object) => (
      <IoShirtOutline color={'#EF476F'} {...props} />
    ),
    [CategoryDescription.SAVINGS]: (props?: object) => (
      <IoWalletOutline color={'#2A9D8F'} {...props} />
    ),
    [CategoryDescription.UTILITIES]: (props?: object) => (
      <IoFlashOutline color={'#FB8500'} {...props} />
    ),
    [CategoryDescription.OTHERS]: (props?: object) => (
      <IoReceiptOutline color={'lightseagreen'} {...props} />
    ),
    [CategoryDescription.SALARY]: (props?: object) => (
      <IoCashOutline color={'#8AC926'} {...props} />
    ),
    [CategoryDescription.TRAVEL]: (props?: object) => (
      <IoAirplaneOutline color={'#B5179E'} {...props} />
    ),
    [CategoryDescription.EDUCATION]: (props?: object) => (
      <IoSchoolOutline color={'#219EBC'} {...props} />
    ),
    [CategoryDescription.OFFICE]: (props?: object) => (
      <IoAttachOutline color={'#50514F'} {...props} />
    ),
  }

  return icons[category]
}
