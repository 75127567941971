import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute/private.route'
import { Auth } from './pages/auth/Auth'
import { Home } from './pages/home/Home'
import { Explorer } from './pages/explorer/Explorer'
// import { Reports } from './pages/reports/Reports'
import { MenuWrapper } from './components/MenuWrapper/MenuWrapper'
import 'react-toastify/dist/ReactToastify.css'
import 'antd/dist/reset.css'
import './App.css'

function App() {
  return (
    <BrowserRouter>
      <MenuWrapper>
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/home" element={<PrivateRoute component={Home} />} />
          <Route path="/explorer" element={<PrivateRoute component={Explorer} />} />
          {/*<Route path="/reports" element={<PrivateRoute component={Reports} />} />*/}
          <Route path="/*" element={<PrivateRoute component={Home} />} />
        </Routes>
      </MenuWrapper>
    </BrowserRouter>
  )
}

export default App
