import { Button, Form, Input } from 'antd'
import { ToastContainer, toast } from 'react-toastify'
import { TOAST_DEFAULTS } from '../../constants/toast'
import { useUserStore } from '../../stores/user.store'
import { AxiosError } from 'axios'
import { UserLoginInput, USER_API } from '../../api/user.api'
import { useNavigate } from 'react-router-dom'

import { Card } from '../../components/Card/Card'
import * as S from './Auth.style'
import { delay } from '../../util/delay'

const Auth = () => {
  const { setToken, clearStore } = useUserStore()
  const navigate = useNavigate()

  const onFinish = async (values: UserLoginInput) => {
    const loginToast = toast.info('Validando credenciais')
    const result = await USER_API.login(values)

    toast.dismiss(loginToast)
    if (result instanceof AxiosError) {
      toast.error('Não foi possível validar suas credenciais')
      localStorage.clear()
      clearStore()
    } else {
      toast.success('Credenciais validadas com sucesso')
      localStorage.setItem('token', JSON.stringify(result))
      setToken(result)
      await delay(1000)
      navigate('/home')
    }
  }

  const onFinishFailed = () => {
    toast.error('Por favor digite credenciais válidas')
    localStorage.clear()
    clearStore()
  }

  return (
    <S.AuthContainer>
      <Card>
        <Form
          requiredMark={false}
          layout={'vertical'}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Digite um email válido' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: 'Digite uma senha' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <ToastContainer {...TOAST_DEFAULTS} />
    </S.AuthContainer>
  )
}

export { Auth }
