import create from 'zustand'
import { UserData } from '../api/user.api'

interface UserState {
  email: string
  username: string
  jwt: string
  setToken(data: UserData): void
  clearStore(): void
}

export const useUserStore = create<UserState>((set) => ({
  email: '',
  username: '',
  jwt: '',
  setToken: ({ email, username, jwt }: UserData) => set({ email, username, jwt }),
  clearStore: () => set({ email: '', username: '', jwt: '' }),
}))
