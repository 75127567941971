import { useEffect, useState } from 'react'
import {
  DollarOutlined,
  ExceptionOutlined,
  WalletOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Col, Divider, Layout, Row, Spin, theme } from 'antd'
import { TRANSACTIONS_API } from '../../api/transaction.api'
import { Transaction } from '../../common/interfaces/transaction.interface'
import { TransactionCard } from '../../components/Card/TransactionCard'
import { TransactionService } from '../../services/transaction.service'
import { TransactionTypeDescription } from '../../common/enums/transactionTypeDescription'
import { HighlightCard } from '../../components/Card/HighlightCard'
import { getCategoryIcon } from '../../util/getCategoryIcon'
import { CategoryDescription } from '../../common/enums/categoryDescription'
import { FadeInOut } from '../../components/FadeInOut/FadeInOut'
import { getFirstDayOfMonth, getLastDayOfMonth } from '../../util/dates'

const { Content } = Layout

const Home = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [loading, setLoading] = useState(true)

  const transactionService = new TransactionService(transactions)
  const categoryWithMostExpenses = transactionService.getCategoryWithMostExpenses()

  useEffect(() => {
    const date = new Date()
    const transactions = TRANSACTIONS_API.getAllTransactions({
      startDate: getFirstDayOfMonth(date.getMonth(), date.getFullYear()).toISOString(),
      endDate: getLastDayOfMonth(date.getMonth(), date.getFullYear()).toISOString(),
    })
    transactions.then((transactions) => {
      setTransactions(transactions)
      setLoading(false)
    })
  }, [])

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  return (
    <Content>
      <div
        style={{
          padding: 24,
          textAlign: 'center',
          transition: `opacity 300ms ease-in-out`,
          background: loading ? 'transparent' : colorBgContainer,
          margin: '16px 16px 0',
          overflow: 'initial',
          borderRadius: '16px',
        }}
      >
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
        <FadeInOut show={!loading}>
          {!loading ? (
            <>
              <Row justify="space-around" gutter={[8, 8]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }}>
                  <HighlightCard
                    icon={<WalletOutlined style={{ fontSize: '3em', color: 'green' }} />}
                    text={'Total de Recebimentos:'}
                    value={transactionService.getTotalIncome()}
                  />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }}>
                  <HighlightCard
                    icon={<ExceptionOutlined style={{ fontSize: '3em', color: 'red' }} />}
                    text={'Total de Despesas:'}
                    value={transactionService.getTotalExpense()}
                  />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }}>
                  <HighlightCard
                    icon={<DollarOutlined style={{ fontSize: '3em', color: 'lightseagreen' }} />}
                    text={'Balanço do mês:'}
                    value={transactionService.getMonthBalance()}
                  />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }}>
                  <HighlightCard
                    icon={getCategoryIcon(
                      (categoryWithMostExpenses?.name as CategoryDescription) ||
                        CategoryDescription.OTHERS,
                    )({ style: { fontSize: '3em' } })}
                    text={categoryWithMostExpenses?.name || 'Sem dados suficientes'}
                    value={categoryWithMostExpenses?.value || 0}
                  />
                </Col>
              </Row>
              <Divider plain>Contas do mês</Divider>
              <Row gutter={[8, 8]}>
                {transactionService
                  .getTransactionsByType(TransactionTypeDescription.EXPENSE)
                  .sort(
                    (a, b) =>
                      Number(a.status) - Number(b.status) ||
                      new Date(a.date).getTime() - new Date(b.date).getTime(),
                  )
                  .map((transaction) => {
                    return (
                      <Col
                        xs={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 6 }}
                        key={transaction.id}
                      >
                        <TransactionCard transaction={transaction} />
                      </Col>
                    )
                  })}
              </Row>
            </>
          ) : (
            <></>
          )}
        </FadeInOut>
      </div>
    </Content>
  )
}

export { Home }
