import { createElement, ReactNode } from 'react'
import { DashboardOutlined, CalculatorOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Layout, Menu } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'

const { Sider } = Layout

type MenuWrapperProps = {
  children: ReactNode
}

const MenuWrapper = ({ children }: MenuWrapperProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const items: MenuProps['items'] = [
    { icon: DashboardOutlined, label: 'Home', path: '/home' },
    { icon: CalculatorOutlined, label: 'Explorer', path: '/explorer' },
  ].map((menuItem, index) => ({
    key: String(index + 1),
    icon: createElement(menuItem.icon),
    label: menuItem.label,
    onClick: () => navigate(menuItem.path),
  }))

  if (location.pathname == '/' || location.pathname == '/auth') return <>{children}</>

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div style={{ height: 4, margin: 16 }} />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={items} />
      </Sider>
      <Layout style={{ marginLeft: 200 }}>{children}</Layout>
    </Layout>
  )
}

export { MenuWrapper }
