import { client } from './client'
import { ALL_TRANSACTION_TYPES } from './routes'

const getAllTransactionTypes = async () => {
  return client
    .get(ALL_TRANSACTION_TYPES)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

export const TRANSACTION_TYPES_API = { getAllTransactionTypes }
