import { ToastContainerProps } from 'react-toastify'

export const TOAST_DEFAULTS = {
  position: 'bottom-right',
  autoClose: 500,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: 'light',
} as ToastContainerProps
