import React, { ReactNode } from 'react'

type CardProps = {
  children: ReactNode
}

export const Card = ({ children }: CardProps): JSX.Element => {
  return <div style={wrapperStyle}>{children}</div>
}

const wrapperStyle = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
  padding: '32px',
}
