import { client } from './client'
import { ALL_CATEGORIES } from './routes'

const getAllCategories = async () => {
  return client
    .get(ALL_CATEGORIES)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

export const CATEGORIES_API = { getAllCategories }
