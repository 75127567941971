import { client } from './client'
import {
  ALL_TRANSACTIONS,
  CREATE_TRANSACTION,
  DELETE_TRANSACTION,
  PATCH_TRANSACTION,
} from './routes'
import { TransactionDto, UpdateTransactionDto } from '../common/interfaces/transaction.interface'

export interface TransactionFilter {
  type?: string
  startDate?: string
  endDate?: string
  target?: string
  category?: string
  status?: boolean
  quotas?: string
}

const getAllTransactions = async (params?: TransactionFilter) => {
  return client
    .get(ALL_TRANSACTIONS, { params })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const createTransaction = async (body: TransactionDto) => {
  return client
    .post(CREATE_TRANSACTION, body)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const deleteTransaction = async (id: string) => {
  return client
    .delete(`${DELETE_TRANSACTION}/${id}`)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const patchTransaction = async (id: string, body: UpdateTransactionDto) => {
  return client
    .patch(`${PATCH_TRANSACTION}/${id}`, body)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

export const TRANSACTIONS_API = {
  getAllTransactions,
  createTransaction,
  deleteTransaction,
  patchTransaction,
}
