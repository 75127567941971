import { Transaction } from '../common/interfaces/transaction.interface'
import { TransactionTypeDescription } from '../common/enums/transactionTypeDescription'
import { CategoryDescription } from '../common/enums/categoryDescription'

export class TransactionService {
  constructor(private readonly transactions: Transaction[]) {
    this.transactions = transactions
  }

  public getTransactionsFromCategory(category: CategoryDescription): Transaction[] {
    return this.transactions?.filter((transaction) => transaction.category.description == category)
  }

  public getTransactionsByType(type: TransactionTypeDescription): Transaction[] {
    return this.transactions?.filter((transaction) => transaction.type.description == type)
  }

  public getTransactionsByStatus(status: boolean): Transaction[] {
    return this.transactions?.filter((transaction) => transaction.status == status)
  }

  public getTotalExpense(): number {
    return this.transactions
      ?.filter((transaction) => transaction.type.description === 'Despesa')
      .reduce((acc, transaction) => (acc += transaction.value), 0)
  }

  public getTotalIncome(): number {
    return this.transactions
      ?.filter((transaction) => transaction.type.description === 'Recebimento')
      .reduce((acc, transaction) => (acc += transaction.value), 0)
  }

  public getMonthBalance(): number {
    const expenseTotal = this.getTotalExpense()
    const incomeTotal = this.getTotalIncome()

    return incomeTotal - expenseTotal
  }

  public getCategoryWithMostExpenses() {
    const categories = Object.fromEntries(
      this.transactions?.map((transaction) => [transaction.category.description, 0]),
    )

    this.transactions
      ?.filter((transaction) => transaction.type.description === 'Despesa')
      .forEach((transaction) => (categories[transaction.category.description] += transaction.value))

    const maxCategory = this.sortCategoriesByValueAndGetMax(categories)

    return maxCategory ? { name: maxCategory[0], value: maxCategory[1] } : undefined
  }

  private sortCategoriesByValueAndGetMax(categories: {
    [p: string]: number
  }): [string, number] | undefined {
    return Object.entries(categories).sort((ant, cur) => {
      if (ant[1] < cur[1]) {
        return 1
      } else {
        return -1
      }
    })[0]
  }
}
