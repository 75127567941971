import { TransactionType } from '../common/interfaces/transactionType.interface'
import { TransactionTypeDescription } from '../common/enums/transactionTypeDescription'

export class TransactionTypeService {
  constructor(private readonly transactionTypes: TransactionType[]) {
    this.transactionTypes = transactionTypes
  }

  public getAllTransactionTypes(): TransactionType[] {
    return this.transactionTypes
  }

  public getTransactionType(description: TransactionTypeDescription): TransactionType | undefined {
    return this.transactionTypes?.find(
      (transactionType) => transactionType.description === description,
    )
  }

  public getTransactionTypeId(description: TransactionTypeDescription): string | undefined {
    return this.transactionTypes?.find(
      (transactionType) => transactionType.description === description,
    )?.id
  }

  public getTransactionTypeDescriptionById(id?: string): TransactionTypeDescription | undefined {
    return this.transactionTypes?.find((transactionType) => transactionType.id === id)
      ?.description as TransactionTypeDescription
  }

  public getTransactionTypeSelectOptions(): { value: string; label: string }[] {
    return this.transactionTypes?.map((transactionType) => ({
      value: transactionType.id,
      label: transactionType.description,
    }))
  }
}
