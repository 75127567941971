import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { TOAST_DEFAULTS } from '../../constants/toast'
import {
  Button,
  Checkbox,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
} from 'antd'
import { Col, Divider, Layout, Row, theme, DatePicker } from 'antd'
import { TRANSACTIONS_API } from '../../api/transaction.api'
import { Transaction, TransactionDto } from '../../common/interfaces/transaction.interface'
import { TransactionCard } from '../../components/Card/TransactionCard'
import { TransactionTypeDescription } from '../../common/enums/transactionTypeDescription'
import { TransactionFilter } from '../../common/interfaces/transactionFilter.interface'
import { getFirstDayOfMonth, getLastDayOfMonth } from '../../util/dates'
import { CategoryDescription } from '../../common/enums/categoryDescription'
import { CategoryService } from '../../services/category.service'
import { Category } from '../../common/interfaces/category.interface'
import { CATEGORIES_API } from '../../api/category.api'
import { TRANSACTION_TYPES_API } from '../../api/transactionType.api'
import { TransactionType } from '../../common/interfaces/transactionType.interface'
import { TransactionTypeService } from '../../services/transactionType.service'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import dayjs from 'dayjs'
import { AxiosError } from 'axios'
import { useForm } from 'antd/es/form/Form'
import { useDebounce } from '../../common/hooks/useDebounce'
import { LoadingOutlined } from '@ant-design/icons'
import { FadeInOut } from '../../components/FadeInOut/FadeInOut'

const { Content } = Layout

const Explorer = () => {
  const defaultDate = new Date()
  const [form] = useForm()
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [transactionTypes, setTransactionTypes] = useState<TransactionType[]>([])
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [nQuotas, setNQuotas] = useState(0)
  const [fullPriceQuotas, setfullPriceQuotas] = useState(false)
  const [hasInstallments, setHasInstallments] = useState(false)
  const [externalChange, setExternalChange] = useState(false)
  const [newTransaction, setNewTransaction] = useState<TransactionDto>({
    date: new Date().toISOString(),
  } as TransactionDto)
  const [filters, setFilters] = useState<TransactionFilter>({
    startDate: getFirstDayOfMonth(defaultDate.getMonth(), defaultDate.getFullYear()).toISOString(),
    endDate: getLastDayOfMonth(defaultDate.getMonth(), defaultDate.getFullYear()).toISOString(),
  })

  const categoryService = new CategoryService(categories)
  const transactionTypeService = new TransactionTypeService(transactionTypes)

  const categorySelectOptions = categoryService.getCategorySelectOptions()
  const transactionTypeSelectOptions = transactionTypeService.getTransactionTypeSelectOptions()

  const showModal = () => {
    setOpen(true)
  }

  const handleOk = async () => {
    setConfirmLoading(true)
    const result = await TRANSACTIONS_API.createTransaction(newTransaction)
    if (result instanceof AxiosError) {
      toast.error('Não foi possível criar sua transação.')
    } else {
      toast.success('Transação criada com sucesso!')
      form.resetFields()
      setHasInstallments(false)
      setExternalChange(!externalChange)
    }
    setOpen(false)
    setConfirmLoading(false)
  }

  const handleCancel = () => {
    form.resetFields()
    setHasInstallments(false)
    setOpen(false)
  }

  const onDateChange: DatePickerProps['onChange'] = (date) => {
    setFilters({
      ...filters,
      startDate: getFirstDayOfMonth(date?.month(), date?.year()).toISOString(),
      endDate: getLastDayOfMonth(date?.month(), date?.year()).toISOString(),
    })
  }

  const onTransactionStatusChange = (value: boolean) => {
    setFilters({
      ...filters,
      status: value,
    })
  }

  const onCategoryChange = (value: CategoryDescription) => {
    setFilters({
      ...filters,
      category: value,
    })
  }

  const onTransactionTypeChange = (value: TransactionTypeDescription) => {
    setFilters({
      ...filters,
      type: value,
    })
  }

  const onFormDateChange: DatePickerProps['onChange'] = (date) => {
    const dt = new Date()
    setNewTransaction({
      ...newTransaction,
      date: new Date(
        date?.year() || dt.getFullYear(),
        date?.month() === 0 || date?.month() ? date.month() : dt.getMonth(),
        date?.date() || dt.getDate(),
      ).toISOString(),
    })
  }

  const onFormTransactionStatusChange = (value: boolean) => {
    setNewTransaction({
      ...newTransaction,
      status: value,
    })
  }

  const onFormCategoryChange = (value: CategoryDescription) => {
    setNewTransaction({
      ...newTransaction,
      category: value,
    })
  }

  const onFormTransactionTypeChange = (value: TransactionTypeDescription) => {
    setNewTransaction({
      ...newTransaction,
      type: value,
    })
  }

  const onFormDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setNewTransaction({
      ...newTransaction,
      description: value,
    })
  }

  const debouncedDescriptionChange = useDebounce(onFormDescriptionChange)

  const onFormTargetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setNewTransaction({
      ...newTransaction,
      target: value,
    })
  }

  const debouncedTargetChange = useDebounce(onFormTargetChange)

  const onFormValueChange = (value: number | null) => {
    if (value) {
      setNewTransaction({
        ...newTransaction,
        value: value,
      })
    }
  }

  const onInstallmentCheckboxChange = (e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      setNewTransaction({
        ...newTransaction,
        quotas: undefined,
      })
    }
    setHasInstallments(e.target.checked)
  }

  const onNQuotasChange = (value: number | null) => {
    if (value) {
      setNQuotas(value)
      setNewTransaction({
        ...newTransaction,
        quotas: `${value}|${fullPriceQuotas}`,
      })
    }
  }

  const onFullPriceQuotasChange = (e: CheckboxChangeEvent) => {
    setfullPriceQuotas(e.target.checked)
    setNewTransaction({
      ...newTransaction,
      quotas: `${nQuotas}|${e.target.checked}`,
    })
  }

  useEffect(() => {
    const transactions = TRANSACTIONS_API.getAllTransactions(filters)
    transactions.then((transactions) => {
      setTransactions(transactions)
      setLoading(false)
    })
  }, [filters, externalChange])

  useEffect(() => {
    const categories = CATEGORIES_API.getAllCategories()
    categories.then((categories) => setCategories(categories))
  }, [])

  useEffect(() => {
    const transactionTypes = TRANSACTION_TYPES_API.getAllTransactionTypes()
    transactionTypes.then((transactionTypes) => setTransactionTypes(transactionTypes))
  }, [])

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  return (
    <Content>
      <div
        style={{
          padding: 24,
          textAlign: 'center',
          background: loading ? 'transparent' : colorBgContainer,
          margin: '16px 16px 0',
          overflow: 'initial',
          borderRadius: '16px',
        }}
      >
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
        <FadeInOut show={!loading}>
          {!loading ? (
            <>
              <Row justify="start">
                <Col style={{ marginRight: '16px' }}>
                  <DatePicker style={{ width: 180 }} onChange={onDateChange} picker="month" />
                </Col>
                <Col style={{ marginRight: '16px' }}>
                  <Select
                    style={{ width: 180 }}
                    placeholder="Status do Pagamento"
                    onChange={onTransactionStatusChange}
                    allowClear
                    options={[
                      {
                        value: true,
                        label: 'Pago',
                      },
                      {
                        value: false,
                        label: 'Pendente',
                      },
                    ]}
                  />
                </Col>
                <Col style={{ marginRight: '16px' }}>
                  <Select
                    style={{ width: 180 }}
                    placeholder="Categoria"
                    onChange={onCategoryChange}
                    allowClear
                    options={categoryService.getCategorySelectOptions()}
                  />
                </Col>
                <Col style={{ marginRight: '16px' }}>
                  <Select
                    style={{ width: 180 }}
                    placeholder="Tipo de Transação"
                    onChange={onTransactionTypeChange}
                    allowClear
                    options={transactionTypeService.getTransactionTypeSelectOptions()}
                  />
                </Col>
                <Col>
                  <Button type="primary" onClick={showModal}>
                    + Nova transação
                  </Button>
                </Col>
              </Row>
              <Divider plain>Contas do mês</Divider>
              <Row key={Math.random().toString()} gutter={[8, 8]}>
                {transactions
                  .sort(
                    (a, b) =>
                      Number(a.status) - Number(b.status) ||
                      new Date(a.date).getTime() - new Date(b.date).getTime(),
                  )
                  .map((transaction) => {
                    return (
                      <Col
                        xs={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 6 }}
                        key={transaction.id}
                      >
                        <TransactionCard
                          transaction={transaction}
                          triggerRefresh={{ trigger: setExternalChange, value: externalChange }}
                          showRemove={true}
                          categorySelectOptions={categorySelectOptions}
                        />
                      </Col>
                    )
                  })}
              </Row>
              <Modal
                title={'Criar Transação'}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
              >
                <Form form={form}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="description"
                        rules={[
                          { required: true, message: 'Digite uma descrição para sua transação' },
                        ]}
                        style={{ marginBottom: '8px' }}
                      >
                        <Input onChange={debouncedDescriptionChange} placeholder="Descricão" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ marginRight: '8px' }}>
                      <Form.Item style={{ marginBottom: '8px' }}>
                        <DatePicker
                          allowClear={false}
                          style={{ width: 180 }}
                          defaultValue={dayjs(new Date())}
                          onChange={onFormDateChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item style={{ marginBottom: '8px' }} name="target">
                        <Input
                          style={{ width: 180 }}
                          onChange={debouncedTargetChange}
                          placeholder="Parte envolvida"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ marginRight: '8px' }}>
                      <Form.Item name="category" style={{ marginBottom: '8px' }}>
                        <Select
                          style={{ width: 180 }}
                          placeholder="Categoria"
                          onChange={onFormCategoryChange}
                          allowClear
                          options={categorySelectOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name="transactionType" style={{ marginBottom: '8px' }}>
                        <Select
                          style={{ width: 180 }}
                          placeholder="Tipo de Transação"
                          onChange={onFormTransactionTypeChange}
                          allowClear
                          options={transactionTypeSelectOptions}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ marginRight: '8px' }}>
                      <Form.Item name="value" style={{ marginBottom: '8px' }}>
                        <InputNumber min={0.01} placeholder="Valor" onChange={onFormValueChange} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name="transactionStatus" style={{ marginBottom: '8px' }}>
                        <Select
                          style={{ width: 180 }}
                          placeholder="Status do Pagamento"
                          onChange={onFormTransactionStatusChange}
                          allowClear
                          options={[
                            {
                              value: true,
                              label: 'Pago',
                            },
                            {
                              value: false,
                              label: 'Pendente',
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Item name="installments" style={{ marginBottom: '8px' }}>
                      <Checkbox onChange={onInstallmentCheckboxChange}>Parcelado?</Checkbox>
                    </Form.Item>
                  </Row>
                  {hasInstallments && (
                    <>
                      <Row style={{ marginBottom: '8px' }}>
                        <Col style={{ marginRight: '8px' }}>
                          <Form.Item name="numberOfQuotas" style={{ marginBottom: '8px' }}>
                            <InputNumber
                              style={{ width: 180 }}
                              min={0.01}
                              placeholder="Número de parcelas"
                              onChange={onNQuotasChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <Form.Item name="fullPrice" style={{ marginBottom: '8px' }}>
                            <Checkbox onChange={onFullPriceQuotasChange}>
                              Este é o valor total da compra?
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form>
              </Modal>
            </>
          ) : (
            <></>
          )}
        </FadeInOut>
      </div>
      <ToastContainer {...TOAST_DEFAULTS} autoClose={1000} />
    </Content>
  )
}

export { Explorer }
