import axios from 'axios'

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

client.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')

  if (token) {
    const jwt = JSON.parse(token).jwt
    // @ts-expect-error TODO: axios bug workaround, ref: https://github.com/axios/axios/issues/5034
    config.headers = { ...config.headers, Authorization: `Bearer ${jwt}` }
  }
  return config
})
