import { Card } from './Card'
import { Col, Row, Typography } from 'antd'

type CardProps = {
  icon: JSX.Element
  text: string
  value: number
}

export const HighlightCard = (cardInfo: CardProps): JSX.Element => {
  const { Text } = Typography
  return (
    <Card>
      <Row>
        <Col style={{ display: 'flex', alignItems: 'center' }} span={8}>
          {cardInfo.icon}
        </Col>
        <Col
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          span={16}
        >
          <Row>{cardInfo.text}</Row>
          <Row>
            <Text code style={{ margin: 0 }}>
              {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                cardInfo.value,
              )}
            </Text>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
