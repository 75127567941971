import { client } from './client'
import { AxiosError } from 'axios'
import { SIGN_IN_ROUTE } from './routes'

export interface UserData {
  email: string
  username: string
  jwt: string
}

export interface UserLoginInput {
  email: string
  password: string
}

const login = async (body: UserLoginInput): Promise<UserData | AxiosError> => {
  return client
    .post(SIGN_IN_ROUTE, body)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

export const USER_API = { login }
