import { useEffect, useState } from 'react'

const UNMOUNTED = 'unmounted'
const EXITED = 'exited'
const ENTERED = 'entered'

type TransitionStyles = {
  [key: string]: {
    opacity: number
  }
}

const transitionStyles: TransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

type FadeInOutProps = {
  children: JSX.Element
  show: boolean
  duration: number
}

const FadeInOut = ({ children, show, duration }: FadeInOutProps) => {
  const [status, setStatus] = useState(UNMOUNTED)

  useEffect(() => {
    if (show) {
      setStatus(ENTERED)
    } else {
      setStatus(EXITED)
    }
  }, [show])

  return (
    <div
      style={{
        transition: `opacity ${duration}ms ease-in-out`,
        ...transitionStyles[status],
      }}
    >
      {children}
    </div>
  )
}

FadeInOut.defaultProps = {
  showState: false,
  duration: 300,
}

export { FadeInOut }
