import { Category } from '../../common/interfaces/category.interface'
import { TransactionType } from '../../common/interfaces/transactionType.interface'
import { Card } from './Card'
import {
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd'
import { WalletOutlined, ExceptionOutlined } from '@ant-design/icons'
import { getCategoryIcon } from '../../util/getCategoryIcon'
import { CategoryDescription } from '../../common/enums/categoryDescription'
import { IoTrashBinOutline } from 'react-icons/io5'
import { TRANSACTIONS_API } from '../../api/transaction.api'
import { AxiosError } from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { TOAST_DEFAULTS } from '../../constants/toast'
import { Dispatch, SetStateAction, useState } from 'react'
import { TransactionDto, UpdateTransactionDto } from '../../common/interfaces/transaction.interface'
import { useForm } from 'antd/es/form/Form'
import { useDebounce } from '../../common/hooks/useDebounce'
import dayjs from 'dayjs'

type TransactionProps = {
  id: string
  description: string
  date: string
  target?: string
  value: number
  status: boolean
  quotas?: string
  category: Category
  type: TransactionType
}

type CardProps = {
  transaction: TransactionProps
  triggerRefresh?: { trigger: Dispatch<SetStateAction<boolean>>; value: boolean }
  showRemove?: boolean
  categorySelectOptions?: { value: string; label: string }[]
}

export const TransactionCard = ({
  transaction,
  triggerRefresh,
  showRemove = false,
  categorySelectOptions,
}: CardProps): JSX.Element => {
  const { Text } = Typography
  const isPaid = transaction.status
  const categoryIcon = getCategoryIcon(transaction.category.description as CategoryDescription)

  const [updatedTransaction, setUpdatedTransaction] = useState<UpdateTransactionDto>({})
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [form] = useForm()

  // MODAL CONTROLS
  const [categoryOpen, setCategoryOpen] = useState(false)
  const [descriptionOpen, setDescriptionOpen] = useState(false)
  const [dateOpen, setDateOpen] = useState(false)
  const [targetOpen, setTargetOpen] = useState(false)
  const [valueOpen, setValueOpen] = useState(false)
  const [statusOpen, setStatusOpen] = useState(false)

  const patchTransaction = async () => {
    setConfirmLoading(true)

    const result = await TRANSACTIONS_API.patchTransaction(transaction.id, updatedTransaction)
    if (result instanceof AxiosError) {
      toast.error('Não foi possível modificar sua transação.')
    } else {
      toast.success('Transação modificada com sucesso!')
      triggerRefresh?.trigger(!triggerRefresh?.value)
    }
    setCategoryOpen(false)
    setDescriptionOpen(false)
    setDateOpen(false)
    setTargetOpen(false)
    setValueOpen(false)
    setStatusOpen(false)
    form.resetFields()
    setConfirmLoading(false)
  }

  const deleteTransaction = async () => {
    toast.info('Removendo transação...')
    const result = await TRANSACTIONS_API.deleteTransaction(transaction.id)
    if (result instanceof AxiosError) {
      toast.error('Não foi possível remover sua transação.')
    } else {
      toast.success('Transação removida com sucesso!')
      triggerRefresh?.trigger(!triggerRefresh?.value)
    }
  }

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setUpdatedTransaction({
      description: value,
    })
  }

  const debouncedDescriptionChange = useDebounce(onDescriptionChange)

  const onTargetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setUpdatedTransaction({
      target: value,
    })
  }

  const debouncedTargetChange = useDebounce(onTargetChange)

  const onValueChange = (value: number | null) => {
    if (value) {
      setUpdatedTransaction({
        value: value,
      })
    }
  }

  const onDateChange: DatePickerProps['onChange'] = (date) => {
    const dt = new Date()
    setUpdatedTransaction({
      date: new Date(
        date?.year() || dt.getFullYear(),
        date?.month() || dt.getMonth(),
        date?.date() || dt.getDate(),
      ).toISOString(),
    })
  }

  const buildCategoryModal = () => {
    return (
      <Modal
        key={'categoryModal'}
        title={'Alterar categoria'}
        open={categoryOpen}
        onOk={patchTransaction}
        onCancel={() => {
          form.resetFields()
          setCategoryOpen(false)
          setUpdatedTransaction({} as TransactionDto)
        }}
        confirmLoading={confirmLoading}
      >
        <Form form={form}>
          <Form.Item name="category">
            <Select
              style={{ width: 180 }}
              placeholder="Categoria"
              onChange={(value: CategoryDescription) => setUpdatedTransaction({ category: value })}
              allowClear
              options={categorySelectOptions}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const buildDescriptionModal = () => {
    return (
      <Modal
        key={'descriptionModal'}
        title={'Alterar descrição '}
        open={descriptionOpen}
        onOk={patchTransaction}
        onCancel={() => {
          form.resetFields()
          setDescriptionOpen(false)
          setUpdatedTransaction({} as TransactionDto)
        }}
        confirmLoading={confirmLoading}
      >
        <Form form={form}>
          <Form.Item
            name="description"
            rules={[{ required: true, message: 'Digite uma descrição para sua transação' }]}
          >
            <Input onChange={debouncedDescriptionChange} placeholder="Descricão" />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const buildTargetModal = () => {
    return (
      <Modal
        key={'targetModal'}
        title={'Alterar parte envolvida'}
        open={targetOpen}
        onOk={patchTransaction}
        onCancel={() => {
          form.resetFields()
          setTargetOpen(false)
          setUpdatedTransaction({} as TransactionDto)
        }}
        confirmLoading={confirmLoading}
      >
        <Form form={form}>
          <Form.Item
            name="target"
            rules={[{ required: true, message: 'Digite uma parte envolvida para sua transação' }]}
          >
            <Input onChange={debouncedTargetChange} placeholder="Parte Envolvida" />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const buildValueModal = () => {
    return (
      <Modal
        key={'valueModal'}
        title={'Alterar valor'}
        open={valueOpen}
        onOk={patchTransaction}
        onCancel={() => {
          form.resetFields()
          setValueOpen(false)
          setUpdatedTransaction({} as TransactionDto)
        }}
        confirmLoading={confirmLoading}
      >
        <Form form={form}>
          <Form.Item name="value">
            <InputNumber min={0.01} placeholder="Valor" onChange={onValueChange} />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const buildDateModal = () => {
    return (
      <Modal
        key={'dateModal'}
        title={'Alterar data'}
        open={dateOpen}
        onOk={patchTransaction}
        onCancel={() => {
          form.resetFields()
          setDateOpen(false)
          setUpdatedTransaction({} as TransactionDto)
        }}
        confirmLoading={confirmLoading}
      >
        <Form form={form}>
          <Form.Item style={{ marginBottom: '8px' }}>
            <DatePicker
              allowClear={false}
              style={{ width: 180 }}
              defaultValue={dayjs(new Date())}
              onChange={onDateChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const buildStatusModal = () => {
    return (
      <Modal
        key={'statusModal'}
        title={'Alterar status'}
        open={statusOpen}
        onOk={patchTransaction}
        onCancel={() => {
          form.resetFields()
          setStatusOpen(false)
          setUpdatedTransaction({} as TransactionDto)
        }}
        confirmLoading={confirmLoading}
      >
        <Form form={form}>
          <Form.Item name="transactionStatus" style={{ marginBottom: '8px' }}>
            <Select
              style={{ width: 180 }}
              placeholder="Status do Pagamento"
              onChange={(value) =>
                setUpdatedTransaction({
                  status: value,
                })
              }
              allowClear
              options={[
                {
                  value: true,
                  label: 'Pago',
                },
                {
                  value: false,
                  label: 'Pendente',
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  return (
    <Card>
      <Row>
        <Col onClick={() => setCategoryOpen(true)} flex="24px">
          {categoryIcon({ size: 24 })}
        </Col>
        <Col
          flex="auto"
          style={{
            fontSize: '16px',
            alignSelf: 'center',
            textAlign: 'start',
            paddingLeft: '8px',
          }}
          onClick={() => setDescriptionOpen(true)}
        >
          {transaction.description}
        </Col>
        {showRemove ? (
          <Col>
            <IoTrashBinOutline color="red" onClick={deleteTransaction} />
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <p></p>
      <Row style={{ flexFlow: 'row' }} justify="space-between">
        <Col>
          {transaction.target && (
            <div
              onClick={() => setTargetOpen(true)}
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '20ch',
                overflow: 'hidden',
              }}
            >
              {transaction.target}
            </div>
          )}
        </Col>
        <Col onClick={() => setValueOpen(true)}>
          <Text code style={{ margin: 0 }}>
            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
              transaction.value,
            )}
          </Text>
        </Col>
      </Row>
      <Row style={{ marginTop: '24px' }} justify="space-between">
        <Col onClick={() => setDateOpen(true)} style={{ display: 'flex' }}>
          <div style={{ alignSelf: 'flex-end' }}>
            {new Intl.DateTimeFormat('pt-BR').format(new Date(transaction.date))}
          </div>
        </Col>
        <Col onClick={() => setStatusOpen(true)} style={{ alignSelf: 'flex-end' }}>
          {isPaid ? (
            <WalletOutlined style={{ fontSize: '24px', color: 'green' }} />
          ) : (
            <ExceptionOutlined style={{ fontSize: '24px', color: 'red' }} />
          )}
        </Col>
      </Row>
      {showRemove && buildCategoryModal()}
      {showRemove && buildDescriptionModal()}
      {showRemove && buildTargetModal()}
      {showRemove && buildValueModal()}
      {showRemove && buildDateModal()}
      {showRemove && buildStatusModal()}
      <ToastContainer {...TOAST_DEFAULTS} autoClose={1000} />
    </Card>
  )
}
