import { CategoryDescription } from '../common/enums/categoryDescription'
import { Category } from '../common/interfaces/category.interface'

export class CategoryService {
  constructor(private readonly categories: Category[]) {
    this.categories = categories
  }

  public getAllCategories(): Category[] {
    return this.categories
  }

  public getCategory(description: CategoryDescription): Category | undefined {
    return this.categories?.find((category) => category.description === description)
  }

  public getCategoryId(description: CategoryDescription): string | undefined {
    return this.categories?.find((category) => category.description === description)?.id
  }

  public getCategoryDescriptionById(id?: string): CategoryDescription | undefined {
    return this.categories?.find((category) => category.id === id)
      ?.description as CategoryDescription
  }

  public getCategorySelectOptions(): { value: string; label: string }[] {
    const categories = this.categories?.sort((category) => category.order)

    return categories?.map((category) => ({
      value: category.id,
      label: category.description,
    }))
  }
}
