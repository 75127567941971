export const getFirstDayOfMonth = (month?: number, year?: number) => {
  year ??= new Date().getFullYear()
  month ??= new Date().getMonth()

  return new Date(year, month, 1, 0, 0, 1)
}

export const getLastDayOfMonth = (month?: number, year?: number) => {
  year ??= new Date().getFullYear()
  month ??= new Date().getMonth()

  return new Date(year, month + 1, 0, 23, 59, 59)
}
