export enum CategoryDescription {
  FOOD = 'Alimentação',
  LEISURE = 'Lazer',
  HOSPITAL = 'Hospitalar',
  OTHERS = 'Outros',
  UTILITIES = 'Utilidades',
  TRANSPORT = 'Transporte',
  INSURANCE = 'Seguros',
  CLOTHES = 'Vestuário',
  SAVINGS = 'Economias',
  HOUSING = 'Habitacional',
  SALARY = 'Salário',
  TRAVEL = 'Viagem',
  EDUCATION = 'Educação',
  OFFICE = 'Escritório',
}
