import React from 'react'
import { useUserStore } from '../../stores/user.store'
import { Navigate } from 'react-router-dom'

interface PropType {
  component: React.FC
}

const PrivateRoute: React.FC<PropType> = ({ component: Component }) => {
  const jwt = useUserStore((state) => state.jwt)
  const storageJwt = localStorage.getItem('token')

  if (jwt && storageJwt) {
    if (jwt === JSON.parse(storageJwt).jwt) {
      return <Component />
    } else {
      return <Navigate to={{ pathname: '/auth' }} />
    }
  } else {
    return <Navigate to={{ pathname: '/auth' }} />
  }
}

export { PrivateRoute }
